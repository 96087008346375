.css_mkTable {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    --mkt-color: #0918a1;
    --mkt-color-btn: #0918a1;

    ._title,
    ._body {
        border-bottom: 1px solid #0000001a;
    }

    ._title {
        .mkt_col{
            font-weight: 500;
            color: #666;
            text-transform: uppercase;
            line-height: 38px;
            padding: 0 0;
            font-size: .85em;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &._controls{
                padding-left: 14px;

                & + .mkt_col{padding: 0 5px !important;}
            }
        }
    }

    ._body{
        overflow-x: auto;
        overflow-y: visible;
        height: fit-content;
        position: relative;
    }

}

.mkt_row {
    width: fit-content;
    min-width: 100%;
    // cursor: grab;
    display: flex;
    flex-direction: column;
    line-height: 20px;
    position: relative;

    --status-color: #2b38621a;

    .line{
        width: fit-content;
        min-width: 100%;
        align-content: stretch;
        display: flex;

        &:not(._subitems)._border{
            // pointer-events: none;
            // position: absolute;
            // height: 100%;
            .mkt_col{
                // border-right: 1px solid #0000001a;
                // height: 100% !important;
                &:not(:last-child) {
                    border-right: 1px solid #0000001a;
                }
            }
        }

        &._hover:hover{
            background-color: #428cff11 !important;
            
            ._controls .del{background-color: var(--danger); color: white;}
        }
    }

    &._title, .__title{
            .fa-lock{opacity: 0.7; font-size: .7em; position: relative; top: -1px; margin-right: 4px;}
    }

    &:not(._subitems):nth-child(even) {
        background-color: #0000670a;
    }

    &:nth-child(even) ._controls{background-color: #f4f4f8dd;}
    &:nth-child(odd) ._controls{background-color: #FCFCFEdd;}

    ._title,
    ._body {
        width: 100%;
    }

    .__status{
        // padding-left: 10px;
        border-left: 4px solid var(--status-color);
    }
}

.mkt_col {
    width: 100%;
    height: 40px;
    min-width: var(--width);
    max-width: var(--width);
    // padding: 6px 8px;
    overflow: visible;
    position: relative;

    &.has-change{
        &::after{
            content: '';
            display: block;
            width: 4px;height: 4px;
            background-color: var(--mkt-color);
            top: 0;right: 0;
            position: absolute;
            animation-name: _fade;
            animation-duration: 2s;
        }

        @keyframes _fade {
            0%{opacity: 1;}
            100%{opacity: 0;}
        }
    }

    &.disabled{
        opacity: .5;
        filter: saturate(0);
    }

    //! COLUMN TYPES

    &.col-text, &.col-longtext {
        --width: 320px;
        // --width: 100%;
        // min-width: 280px !important;
        text-align: left;
    }
    &.col-number {
        --width: 120px;
    }
    &.col-user {
        --width: 80px;
    }
    &.col-check {
        --width: 80px;
    }
    &.col-status, &.col-status-change {
        --width: 180px;
    }
    &.col-import-btn{
        --width: 115px;
    }
    &.col-select {
        --width: 240px;
    }
    &.col-dynamic-select {
        --width: 280px;
    }
    &.col-rating {
        --width: 130px;
    }
    &.col-log {
        --width: 180px;
    }
    &.col-date {
        --width: 160px;
    }
    &.col-link {
        --width: 150px;
    }
    &.col-files,&.col-images {
        --width: 120px;
    }
    &.col-tag {
        --width: 120px;
    }
    &.col-bid {
        --width: 130px;
    }
    &.col-currency {
        --width: 150px;
    }
    &.col-detail{
        --width: 40px;
    }
    &.col-pdf {
        --width: 120px;
    }

    &:not(.col-text,.col-select,.col-longtext){text-align: center;}

}
._subitems{
    padding: 20px 0 30px;
    z-index: 2;

    &::before, .card .mkt_row::before{
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        background-color: var(--mkt-color);
        opacity: 0.3;
    }
    &::before{
        width: 2px;
        height: calc(100% - 40px);
        top: -8px;
        left: 55px;
    }
    .card .mkt_row{
        &:first-child .line:first-child{ border-top-left-radius: 11px; border-top-right-radius: 11px; .col-detail{border-top-left-radius: 8px;} }
        &:last-child .line:first-child{ border-bottom-left-radius: 11px; border-bottom-right-radius: 11px; .col-detail{border-bottom-left-radius: 8px;} }

        &::before{
            height: 2px;
            width: 23px;
            top: 50%;
            left: -23px;
        }
    }

    ._controls{
        background-color: transparent !important;
        position: relative;
        --width: 80px !important;
        pointer-events: none;

        &::before, &::after{
            content: '';
            display: block;
            position: absolute;
            background-color: #7390CE;
        }
        
        &.float{
            position: absolute;
            top: 0;
            left: -80px;
        }

        .fa-lock{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8em;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            color: $white;
            background-color: var(--mkt-color);
            position: absolute;
            top: calc(50% - 9px);
            right: 15px;
            filter: brightness(1.6) saturate(0.3);
        }

        .btns button{pointer-events: all;}
    }
    .card{
        width: calc(100% - 10px); 
        margin: 0 10px 0 0;
        // background-color: #DFE9FC;
        background-color:var(--white) !important;

        .mkt_row{
            background-color: transparent !important;

            &:not(:last-child){border-bottom: 1px solid #0001;}
        }

        .mkt_col{
            &:last-child{
                min-width: calc(var(--width) - 10px);
                max-width: calc(var(--width) - 10px);
            }
        }

        .form-control{border-bottom-left-radius: 10px;}
    }

    &.no-data{
        .form-control{border-top-left-radius: 10px;border-top-right-radius: 10px;}
    }

    .__title .mkt_col{
        height: fit-content;
        font-size: .8em;
        opacity: 0.7;
        font-weight: 500;
        position: relative;
        top: -10px;
        padding: 0 0;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.mkt_col._controls{
    display: flex;
    --width: 140px;
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid #0000001a;

    .btns{
        &:only-child{width: 100%;}
        &:not(:only-child){width: calc(var(--width) - 70px);}
        display: flex;
        justify-content: space-between;
        padding: 0 4px;
        position: relative;
    }   
    .folio{
        width: 70px;
        display: flex;
        span{
            font-weight: 500;
            margin: auto;
            color: var(--mkt-color-btn);
        }
        small span{
            font-size: 11px;
            color: #666;
            text-transform: lowercase;
            font-weight: 400;
        }
    }

    
    .dot{
        content: '';
        display: block;
        min-width: 14px;
        height: 14px;
        position: absolute;
        top: 2px;
        right: -2px;
        z-index: 10;
        background-color: var(--danger);
        box-shadow: 0 0 6px var(--danger);
        border-radius: 50%;
        font-size: 10px;
        color: white;
        font-weight: 600;
        line-height: 14px;
    }
}

.mkt_col._controls, ._delete{
    button{
        width: 24px;height: 24px;
        border-radius: 10px;
        border: 0;
        margin: auto 0;
        color: white;
        text-align: center;
        line-height: 24px;
        padding: 0;
        z-index: 2;

        &:disabled{opacity: 0.4;}

        &.dropdown:not(.active){background-color: var(--dark);}
        &.dropdown.active{
            background-color: var(--mkt-color);
            box-shadow: 0 0 6px var(--mkt-color);

            i{transform: rotate(180deg) translateY(2px);}
        }

        &.det{background-color: var(--mkt-color-btn);}
        &.del{
            color: var(--danger);
            background-color: #c2c5cf66;
        }
    }
}

.mkt_row.gu-mirror {
    width: 800px !important;height: 40px !important;
    background-color: white;
    border: 1px solid #0000001a;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 10px #0005;
    z-index: 10;
    cursor: grabbing;

    ._subitems{display: none;}

    .mkt_col {
        width: 100%;
        padding: 6px 8px;

        &:not(:last-child) {border-right: 1px solid #0000001a;}

        // &.col-text{--width: 300px}
    }
}


._hide_table{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding-left: 0;padding-right: 0;
    text-align: center;
  }

._delete{
    // position: absolute;
    // right: 0;
    // top: 0;
    height: 40px;
    width: auto;
    overflow: hidden;
    display: flex;
    flex: 1;

    .ml-auto{display: flex; width: 60px;}

    button{
        margin: auto;
        color: white;
        background-color: var(--danger);
    }

    small{
        margin: auto;
        span{
            font-size: 11px;
            color: #666;
            text-transform: lowercase;
            font-weight: 400;
        }
    }

  }

  mkt-row{position: relative;}


.mkt_dropdown{
    box-sizing: content-box;
    height: fit-content;
    width: fit-content;

    &:not(.size){
        max-width: calc(var(--width) + 10px) !important;
        min-width: calc(var(--width) + 10px) !important;
    }
    
    .head{
        padding: 10px 0;
        border-bottom: 1px solid #0003;
        font-weight: 500;
        opacity: 0.7;
        text-align: center;
    }
    .content{
        padding: 10px 5px;

        img{min-width: 500px; max-height: calc(90vh); max-width: calc(100vw - 200px);}
        ._block{
            line-height: 1.2;
            min-height: 24px;
            height: fit-content;
            padding: 4px;
        }
    }

    .close{
        padding: 10px;
        position: absolute;
        top: 0;
        right: 0;
    }

    ._browser{
        border-bottom: 1px solid #0000001a;
        padding: 6px;
        position: sticky;
        top: 0;
        // z-index: 10;
        background: var(--white);

        input{
            width: 100%;
            margin: 0;
            font-weight: 400;
            padding-left: 24px !important;
            color: black;
            line-height: 1.2 !important;
        }

        .fa{
            position: absolute;
            color: #2b386227;
            top: 12px;
            left: 12px;
        }
    }
}